import { Foooter, Top, Container, Content, Bottom, style } from "./styles"
import { useState, useEffect} from 'react'
import logo from "../../assets/image2vector3.svg"
import { FaInstagram, FaWhatsapp } from "react-icons/fa";

export function Footer() {
    const [year, setYear] = useState()

    useEffect(() => {
        const date = new Date();
        setYear(date.getFullYear());
    }, [])

    return (
        <Foooter>
            <Top>
                <Container>
                    <Content>
                        <div id="logo-footer">
                            <a href="#home">
                                <img src={logo} alt="logo-prc" />
                            </a>
                        </div>
{/*                         <div id="text-footer">
                            <span>Quer trabalhar conosco?</span>
                            <button className="work-button" onClick={() => setShow(true)}>Clique Aqui!</button>
                            <Modal onClose={() => setShow(false)} show={show} />
                        </div> */}
                        <div id="logo-social">
                            <div>
                                <div className="div-logo">
                                    <a href="https://www.instagram.com/prc.invest/" target="_blank" rel="noreferrer"><FaInstagram style={style} /></a>
                                </div>
                                <div className="div-logo">
                                    <a href="https://api.whatsapp.com/send?phone=5521967799759" target="_blank" rel="noreferrer"><FaWhatsapp style={style} /></a>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Container>
            </Top>
            <Bottom>
                <Container>
                    <Content>
                        <div className="div-bottom">
                            <span className="span-bottom">Menu</span>
                            <ul>
                                <li><a className="list-link" href="#home">Início</a></li>
                                <li><a className="list-link" href="#about">Quem Somos</a></li>
                                <li><a className="list-link" href="#negotiation">Negociação</a></li>
                                <li><a className="list-link" href="#feedback">Depoimentos</a></li>
                                <li><a className="list-link" href="#contact">Venda seu Precatório</a></li>
                            </ul>
                        </div>
                        <div className="div-bottom">
                            <p id="p-bottom">© Copyright {year}&nbsp;                                
                                <a href="">PRC Invest</a>
                            </p>
                        </div>
                    </Content>
                </Container>
            </Bottom>
        </Foooter>
    )
}